//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseLayout from "@/layouts/Base";
import CuratorPaginated from "@/components/CuratorPaginated";
import { contest_service } from "@/services";
export default {
  name: "top-curator-page",
  components: {
    BaseLayout,
    CuratorPaginated,
  },
  metaInfo: {
    title: "Top Curators",
  },
  data() {
    return {
      contests: [],
      tab: "",
    };
  },
  mounted() {
    this.load_live_monthly_contests();
  },
  methods: {
    load_live_monthly_contests() {
      contest_service
        .get({ ordering: "start", live: "true", type__name: "Monthly" })
        .then((data) => {
          this.contests.push(...data.results);
          if (this.contests.length > 0)
            this.tab = this.contests[this.contests.length - 1].name;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    on_profile_select(creator) {
      this.$router.push({ name: "profile", params: { id: creator.id } });
    },
  },
};
